/* eslint-disable max-classes-per-file */
import {
    httpRequest,
    type HttpRequestInit,
    type Response,
    UseVinovestNetworkingOptions,
    VinovestNetworkingClient
} from '~/networking';
import type { ClientsideAuthentication } from '~/stores/ClientsideAuthentication';

const defaultConfig: UseVinovestNetworkingOptions = {
    getPortfolioId: () => '',
    getTradingPortfolioId: () => '',
    getUserId: () => ''
};

export interface VinovestNetworkHelperAuthenticationParams {
    userId?: string;
    portfolioId?: string;
    currencyCode?: string;
}
/**
 * Helper to get the vinonvest networking client based on client/server side requirements and okta availability.
 */
export class VinovestNetworkingHelper {
    static instance: VinovestNetworkingClient;

    static getUnauthenticatedClient(): VinovestNetworkingClient {
        return new VinovestNetworkingClient(defaultConfig);
    }

    static getClient(params?: VinovestNetworkHelperAuthenticationParams): VinovestNetworkingClient {
        if (!VinovestNetworkingHelper.instance) {
            // the problem here is that this is called from all sorts of places and the auth is stored in the
            // react tree. This class has had most of the functionality removed already but this last hack is
            // needed before ripping out most of how this is done, it's just a large charge.

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
            const auth: ClientsideAuthentication | undefined = typeof window !== 'undefined' && window?.Vino?.auth;
            const getPortfolioId = () => auth?.userId as string;
            const getUserId = () => auth?.userId as string;
            const getTradingPortfolioId = () => auth?.tradingPortfolioId || '';
            const getCurrencyCode = () => params?.currencyCode || 'USD';

            VinovestNetworkingHelper.instance = new VinovestNetworkingClient({
                getPortfolioId,
                getUserId,
                getTradingPortfolioId,
                getCurrencyCode
            });
        }
        return VinovestNetworkingHelper.instance;
    }

    static getManaged(): ManagedClient {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return new ManagedClient();
    }
}

class ManagedClient {
    private getOptions(httpRequestInit?: HttpRequestInit): HttpRequestInit {
        const { config } = VinovestNetworkingHelper.getClient().managed;
        return {
            ...httpRequestInit,
            headers: { ...config.getHeaders(true), ...httpRequestInit?.headers }
        };
    }

    private makeUrl(path: string) {
        const { config } = VinovestNetworkingHelper.getClient().managed;
        return config.getRequestUrl(path);
    }

    async get<T>(path: string, httpRequestInit?: HttpRequestInit): Promise<Response<T>> {
        const url = this.makeUrl(path);
        const options = this.getOptions(httpRequestInit);
        return httpRequest.get(url, options);
    }

    async post<T>(path: string, httpRequestInit?: HttpRequestInit): Promise<Response<T>> {
        const url = this.makeUrl(path);
        const options = this.getOptions(httpRequestInit);
        return httpRequest.post(url, options);
    }

    async put<T>(path: string, httpRequestInit?: HttpRequestInit): Promise<Response<T>> {
        const url = this.makeUrl(path);
        const options = this.getOptions(httpRequestInit);
        return httpRequest.put(url, options);
    }

    async patch<T>(path: string, httpRequestInit?: HttpRequestInit): Promise<Response<T>> {
        const url = this.makeUrl(path);
        const options = this.getOptions(httpRequestInit);
        return httpRequest.patch(url, options);
    }

    async delete<T>(path: string, httpRequestInit?: HttpRequestInit): Promise<Response<T>> {
        const url = this.makeUrl(path);
        const options = this.getOptions(httpRequestInit);
        return httpRequest.delete(url, options);
    }
}
