import { getHostPathGenerator } from '~/networking';

import { headerFactory } from '../../headerFactory';
import { ApiGenerationOptions, ApiTypes } from '../../VinovestNetworkingClient';

export type ManagedInitConfig = Exclude<ApiGenerationOptions, 'strapiToken'>;

export type ManagedConfig = {
    getPortfolioId: () => string;
    getUserId: () => string;
    getCurrencyCode: () => string;
    getRequestUrl: (pathName: string) => string;
    getHeaders: (
        secure?: boolean,
        extraHeaders?: { [key: string]: string } | Headers
    ) =>
        | {
              [key: string]: string;
          }
        | Headers;
};

export const managedInititializationConfig = ({
    getAccessToken,
    getPortfolioId,
    getCurrencyCode,
    getUserId
}: ManagedInitConfig): ManagedConfig => {
    return {
        getPortfolioId,
        getUserId,
        getCurrencyCode,
        getRequestUrl: getHostPathGenerator({ type: ApiTypes.Managed }),

        getHeaders: (secure?: boolean, extraHeaders?: { [key: string]: string } | Headers) =>
            headerFactory({ secure, getAccessToken, getCurrencyCode, type: ApiTypes.Managed, headers: extraHeaders })
    };
};
